
:global {
    html, body {
        font-family: "Nunito Sans", sans-serif;
        scroll-behavior: smooth;
        background-color: #EBEBEB;
    }
}

.app {
    .titlePage {
        color: #000;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        @media only screen and (max-width: 600px) {
            flex-direction: column;
            justify-content: flex-start;
        }

        .header {
            padding-top: 60px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            
            @media only screen and (max-width: 600px) {
                margin-top: 20px;
            }
            
            h1 {
                font-weight: normal;
                margin: 0;

                @media only screen and (max-width: 600px) {
                    font-size: 25px;
                }
            }

            a {
                color: #000;
            }

            .logo {
                width: 200px;
                border-radius: 10px;
                margin-bottom: 20px;

                @media only screen and (max-width: 600px) {
                    width: 150px;
                    margin-bottom: 10px;
                }
            }

            .download {
                width: 150px;
                padding: 20px 0px;

                @media only screen and (max-width: 600px) {
                    width: 100px;
                    padding: 10px 0px;
                }
            }
        }
    }
}

.btn {
    text-decoration: none;
    color: #000;
    width: fit-content;
    padding: 10px;
    background: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background: rgb(238, 238, 238);
    }
}